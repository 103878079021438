import React from 'react';

function IconSorting() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.4697 7.53033C13.7626 7.82322 14.2374 7.82322 14.5303 7.53033L15.25 6.81066L15.25 17C15.25 17.4142 15.5858 17.75 16 17.75C16.4142 17.75 16.75 17.4142 16.75 17L16.75 6.81066L17.4697 7.53033C17.7626 7.82322 18.2374 7.82322 18.5303 7.53033C18.8232 7.23744 18.8232 6.76256 18.5303 6.46967L16.5303 4.46967C16.2374 4.17678 15.7626 4.17678 15.4697 4.46967L13.4697 6.46967C13.1768 6.76256 13.1768 7.23744 13.4697 7.53033Z"
        fill="black"
      />
      <path
        d="M8.75 17.1893L9.46967 16.4697C9.76256 16.1768 10.2374 16.1768 10.5303 16.4697C10.8232 16.7626 10.8232 17.2374 10.5303 17.5303L8.53033 19.5303C8.23744 19.8232 7.76256 19.8232 7.46967 19.5303L5.46967 17.5303C5.17678 17.2374 5.17678 16.7626 5.46967 16.4697C5.76256 16.1768 6.23744 16.1768 6.53033 16.4697L7.25 17.1893L7.25 7C7.25 6.58579 7.58579 6.25 8 6.25C8.41421 6.25 8.75 6.58579 8.75 7L8.75 17.1893Z"
        fill="black"
      />
    </svg>
  );
}

export default IconSorting;
