import React from 'react';
import { bool } from 'prop-types';
import SelectSingleFilterPlain from './SelectSingleFilterPlain';
import SelectSingleFilterPopup from './SelectSingleFilterPopup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SelectSingleFilter = props => {
  const { showAsPopup, ...rest } = props;
  const history = useHistory();
  return showAsPopup ? (
    <SelectSingleFilterPopup history={history} {...rest} />
  ) : (
    <SelectSingleFilterPlain history={history} {...rest} />
  );
};

SelectSingleFilter.defaultProps = {
  showAsPopup: false,
};

SelectSingleFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectSingleFilter;
