import React from 'react';
import { useMyContext } from '../../../contexts/StateHolder';
import IconClearFilters from '../../../components/IconClearFilters/IconClearFilters';

import css from './SearchFiltersMobile.module.css';

function SortByWrapper(props) {
  const { resetAll } = props;
  const { setClearDeepFilters } = useMyContext();
  return (
    <span
      className={css.moreFilters}
      onClick={() => {
        setClearDeepFilters(true);
        resetAll();
      }}
    >
      <IconClearFilters />
    </span>
  );
}

export default SortByWrapper;
