import React, { useEffect, useRef } from 'react';
import { bool, node, number, string } from 'prop-types';
import classNames from 'classnames';
import IconFilters from '../../../components/IconFilters/IconFilters';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { CustomSearchForm, Icons, SearchQuickFilters } from '../../../components';
import IconClearFilters from '../../../components/IconClearFilters/IconClearFilters';
import { useMyContext } from '../../../contexts/StateHolder';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../../util/urlHelpers';

import css from './MainPanelHeader.module.css';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    noResultsInfo,
    onOpenFilterModal,
    quickFilters,
    filtersText,
    intl,
    history,
    viewport,
    clearText,
    hasAnyFilter,
    resetAll,
    urlQueryParams,
  } = props;

  const keywordRef = useRef(null);
  const location = useLocation();
  const search = parse(location.search);
  const { clearDeepFilter, setClearDeepFilters } = useMyContext();
  const classes = classNames(rootClassName || css.root, className);

  const handleSubmit = values => {
    const { keywords } = values;
    const extraParams = urlQueryParams ? { ...urlQueryParams, keywords } : { keywords };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, extraParams));
  };

  const keywordsIfAny = search?.keywords;

  useEffect(() => {
    if (clearDeepFilter && !keywordsIfAny) {
      keywordRef.current.value = '';
    } else if (keywordsIfAny) {
      keywordRef.current.value = keywordsIfAny;
    }
  }, [keywordsIfAny, clearDeepFilter]);

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <SearchQuickFilters />
        <div className={css.sortyByWrapper}>
          <span
            className={classNames(css.clearFilters, {
              [css.active]: hasAnyFilter,
            })}
            onClick={() => {
              if (keywordRef && keywordRef.current) {
                keywordRef.current.value = '';
              }
              setClearDeepFilters(true);
              resetAll();
            }}
          >
            <IconClearFilters /> {clearText}
          </span>
          <CustomSearchForm
            className={css.filterSearchBox}
            onSubmit={handleSubmit}
            intl={intl}
            ref={keywordRef}
          />
          <div className={css.rightFilters}>
            <span className={css.moreFilters} onClick={onOpenFilterModal}>
              <IconFilters /> {filtersText}
            </span>
            {sortByComponent}
          </div>
        </div>
      </div>
      {children}
      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
  filtersText: 'Filters',
  clearText: 'Clear',
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
