import React from 'react';
import { bool, node, object, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import { ListingCard, ListingCardMobile, PaginationLinks } from '../../../components';
import { Instagram } from 'react-content-loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isArrayLength } from '../../../util/dataExtractors';

import css from './SearchResultsPanel.module.css';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    setActiveListing,
    isMapVariant,
    searchInProgress,
    isMobile,
    handleLoadMore,
    searchInURL,
    hasNoResult = false,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  const listingCard = l => {
    return isMobile ? (
      <ListingCardMobile
        className={css.listingCard}
        key={l?.id?.uuid}
        listing={l}
        renderSizes={cardRenderSizes(isMapVariant)}
        setActiveListing={setActiveListing}
        isProfile={true}
        isSearchPage={true}
      />
    ) : (
      <ListingCard
        className={css.listingCard}
        key={l?.id?.uuid}
        listing={l}
        renderSizes={cardRenderSizes(isMapVariant)}
        setActiveListing={setActiveListing}
        isProfile={true}
        isSearchPage={true}
      />
    );
  };

  const onScrollLoad = () => {
    const nextSearchParams = { ...searchInURL, page: pagination.page + 1 };
    handleLoadMore(nextSearchParams);
  };

  const instagramLoaders = isSpinner => (
    <div
      className={classNames({
        [css.productLoaders]: !isSpinner,
        [css.spinnerLoaders]: isSpinner,
      })}
    >
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
      <Instagram />
    </div>
  );

  if (hasNoResult) {
    return <></>;
  }

  return (
    <div className={classes}>
      <div className={isMapVariant ? css.listingCardsMapVariant : css.listingCards}>
        {!searchInProgress && pagination ? (
          <InfiniteScroll
            hasMore={pagination ? pagination?.page < pagination?.totalPages : false}
            next={onScrollLoad}
            dataLength={listings?.length || 1000}
            scrollThreshold={0.2}
            loader={instagramLoaders('spinner')}
          >
            {isArrayLength(listings)
              ? listings?.map(l => listingCard(l))
              : instagramLoaders('spinner')}
          </InfiniteScroll>
        ) : (
          instagramLoaders()
        )}
        {props.children}
      </div>
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  isMapVariant: true,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  isMapVariant: bool,
};

export default SearchResultsPanel;
